import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import typographyStyles from "../styles/typography.module.scss"
import buttonStyles from "../styles/buttons.module.scss"
import elementStyles from "../styles/elements.module.scss"

const ProjectTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wpgraphql.projectBy.seo.title}
      description={data.wpgraphql.projectBy.seo.metaDesc}
    />
    <section>
      <div className={elementStyles.container}>
        <h1>{data.wpgraphql.projectBy.title}</h1>
      </div>
    </section>
  </Layout>
)
export default ProjectTemplate
export const query = graphql`
  query($id: Int!) {
    wpgraphql {
      projectBy(projectId: $id) {
        title
        excerpt
        content
        seo {
          title
          metaDesc
        }
      }
    }
  }
`
